<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">

      <a-form-model-item :label="$t('流量')"prop="flow" >
        <a-input v-model="form.flow" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('流量')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('直播预约场次')"prop="liveNum" >
        <a-input-number v-model="form.liveNum" :placeholder="$t('通用.输入.请输入')+$t('直播预约场次')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('积分抵扣比例(除法)')"prop="scoreRate" >
        <a-input-number v-model="form.scoreRate"  :placeholder="$t('通用.输入.请输入')+$t('积分抵扣比例')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('店员返佣比例')"prop="shopclerkCominRate" >
        <a-input v-model="form.shopclerkCominRate" @change="changeshopclerkCominRate()" :placeholder="$t('通用.输入.请输入')+$t('店员返佣比例')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('店长返佣比例')"prop="shopownerCominRate" >
        <a-input v-model="form.shopownerCominRate" @change="changeshopownerCominRate()" :placeholder="$t('通用.输入.请输入')+$t('店长返佣比例')" />
      </a-form-model-item>

      <!--      <a-form-model-item :label="$t('自购返佣积分')"prop="shopCominScore" >-->
      <!--        <a-input v-model="form.shopCominScore" @change="changeshopCominScore()" :placeholder="$t('通用.输入.请输入')+$t('自购返佣积分')" />-->
      <!--      </a-form-model-item>-->





      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getShop, addShop, updateShop,updateShopFlow} from '@/api/shop/shop'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import Template from "@/views/sms/template";
import {allBrand} from "@/api/goods/brand";
import {listShopCategory} from "@/api/shop/shopCategory";
import {listShopQc} from '@/api/shop/shopQc'
import {listShopEntry} from '@/api/shop/shopEntry'
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";





export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,
        flow: null,
        liveNum:null,
        scoreRate:null,
        shopclerkCominRate:null,
        shopownerCominRate:null,
        shopCominScore:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        categoryName: [
          { required: true, message: this.$t('账户类型（店铺）.账户类型名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        categoryNameEn: [
          { required: true, message: this.$t('账户类型（店铺）.账户类型名称-英文')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        categoryNameTc: [
          { required: true, message: this.$t('账户类型（店铺）.账户类型名称-繁体')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      },
      //多语言集成
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('主营行业.分类名称'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'brandName' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      //多语言集成
    }
  },
  filters: {
  },
  created () {
  },
  //多语言集成
  computed: {
    ...mapGetters(['customDict'])
  },
  //多语言集成
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        flow: null,
        liveNum:null,
        scoreRate:null,
        shopclerkCominRate:null,
        shopownerCominRate:null,
        shopCominScore:null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加');
      //多语言集成
      this.initLocale();
      //多语言集成
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShop({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateShopFlow(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addShopAccount(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    //多语言集成

    initLocale() {
      //获取全局配置的语言
      const sysLangList = globalThis.$openLangList;
      //初始化多语言
      let localeList = [];
      let i = 1;
      this.customDict.LocaleEnum.forEach(e => {
        if(sysLangList.includes(e.type)){
          localeList.push({"id": i, "locale": e.type, 'name': ''})
          i++;
        }
      })
      this.form.localeList = localeList;
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    getTranslation(record) {
      if (!this.form.categoryName) {
        return
      }
      let data = {"text": this.form.categoryName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      });

    },
    //多语言集成
    changeshopclerkCominRate() {
      // console.log(i);
      let val=this.form.shopclerkCominRate.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.shopclerkCominRate = val
    },

    changeshopownerCominRate() {
      // console.log(i);
      let val=this.form.shopownerCominRate.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.shopownerCominRate = val
    },
    changeshopCominScore() {
      // console.log(i);
      let val=this.form.shopCominScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.shopCominScore = val
    },



  }
}
</script>
